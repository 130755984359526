<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <template v-else>
      <v-col cols="10" sm="8" class="pa-0 mt-10 py-10 mx-auto">
        <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10">
          {{ item.name }}
        </div>
        <div class="text-sm py-4">{{ item.createdDate }}</div>
        <!-- | Category | Author -->
        <div class="white grid justify-items-center px-0 pt-5">
          <v-col cols="10" sm="9" class="pa-0">
            <!-- <div class="font-bold text-base sm:text-xl my-5 text-left">{{ item.service }}</div> -->
            <div class="text-left mb-10" v-html="item.desc"></div>
          </v-col>
        </div>
      </v-col>
      <div
        class="grey lighten-4 px-0 pt-10 pb-5 mt-5"
        v-if="stories.length > 0">
        <v-col cols="12" sm="10" class="block m-auto px-0">
          <div class="font-bold text-base sm:text-xl mb-7 text-center">
            <span v-if="bahasa">Kisah & Blog</span>
            <span v-else>Stories & Blogs</span>
          </div>
          <slick ref="slick" :options="slickOptionsStory" class="px-5">
            <div
              v-for="(i, index) in stories"
              :key="index"
              @click="goTo('content-detail', i.id, i.category.toLowerCase())"
              class="cursor-pointer mb-3 pa-5 text-left">
              <v-img
                :src="require('@/assets/images/cover_.png')"
                class="rounded align-center pa-5"
                height="250">
                <v-col
                  cols="12"
                  class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                  <div class="text-sm">{{ i.category || "-" }}</div>
                  <div
                    class="text-lg sm:text-xl tracking-wide py-2"
                    v-html="strLimit(i.desc, 50)"></div>
                  <v-btn
                    class="btn-primary-white mt-3 font-bold text-capitalize">
                    <span v-if="bahasa">Baca selengkapnya</span>
                    <span v-else>Read More</span>
                  </v-btn>
                </v-col>
              </v-img>
            </div>
          </slick>
          <button class="btn-white-outlined nextStory-btn">
            <v-icon color="black">arrow_back</v-icon>
          </button>
          <button class="btn-white-outlined backStory-btn">
            <v-icon color="black">arrow_forward</v-icon>
          </button>
        </v-col>
      </div>
      <div
        class="grey lighten-4 grid justify-items-center px-0 py-5 pt-5"
        v-if="news.length > 0">
        <v-col cols="10" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10">
            {{ bahasa ? "Berita" : "News" }}
          </div>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(berita, index) in news"
              :key="index + 'news'"
              @click="goToProgram('news-detail', berita.id)">
              <v-row>
                <v-col cols="3" class="px-0">
                  <img
                    :src="baseUrlImage + berita.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md sm:px-3" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-base pt-0 sm:text-xl">
                    {{ berita.title || "-" }}
                  </div>
                  <div class="text-sm py-2 pa-0">{{ berita.date || "-" }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div
        class="grey lighten-4 grid justify-items-center px-0 py-5"
        v-if="windows.length > 0">
        <v-col cols="10" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10">Windows</div>
          <v-row v-if="windows.length > 0">
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(window, index) in windows"
              :key="index"
              @click="goToProgram('windows-detail', window.id)">
              <v-row>
                <v-col cols="3" class="">
                  <img
                    :src="baseUrlImage + window.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-lg py-0">{{ window.name }}</div>
                  <div
                    class="font-lora text-base py-3"
                    v-html="strLimit(window.desc, 90)"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
export default {
  components: {
    Slick,
  },
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoad: false,
      item: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      windows: [],
      news: [],
      stories: [],
      slickOptionsStory: {
        slidesToShow: 3,
        prevArrow: ".nextStory-btn",
        nextArrow: ".backStory-btn",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  watch: {
    page() {
      this.getData();
    },
    language() {
      this.getData();
    },
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.getProgramDetail();
    },
    async getProgramDetail() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        page: this.page,
      };
      await axios
        .get(this.baseUrl + "/program" + "/" + this.id, { params: param })
        .then((response) => {
          this.item = response.data.data;
          this.windows = response.data.data.donor;
          this.stories = response.data.data.news.filter((item) => {
            return item.category === "Blog" || item.category === "Kisah";
          });
          this.news = response.data.data.news.filter((item) => {
            return item.category === "News" || item.category === "Berita";
          });
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
    goToProgram(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    goTo(link, param, type) {
      this.$router.push({
        name: link,
        params: {
          id: param,
          type: "/" + type,
        },
      });
    },
  },
};
</script>
